import React from 'react'
import PropTypes from 'prop-types'
import rehypeReact from "rehype-react"
import CascadeLink, { ChameleonCascadeLink } from './CascadeLink'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { a: CascadeLink },
}).Compiler

const renderAstWithSameColorLinks = new rehypeReact({
  createElement: React.createElement,
  components: { a: ChameleonCascadeLink },
}).Compiler

export const HTMLContent = ({ content, className, isLinkSameColor = false }) => (
  <div className={className}>
    {
      isLinkSameColor ?
        renderAstWithSameColorLinks(content) :
        renderAst(content)
    }
  </div>
)

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
